// =============================================================================
// _BASE-TYPOGRAPHY.SCSS
// -----------------------------------------------------------------------------
// Site styles.
// =============================================================================

// =============================================================================
// TABLE OF CONTENTS
// -----------------------------------------------------------------------------
//   01. Base
//   02. Lists
//   03. Anchors
//   04. Miscellaneous
//   05. Experimental
// =============================================================================

// Base
// =============================================================================

.ch-txt.is-body,
.ch-prose :is(p, ul, ol) {
	@include ch-txt-variant("body");
}
.ch-txt.is-caps {
	@include ch-txt-variant("caps");
}
.ch-txt.is-caption {
	@include ch-txt-variant("caption");
}
.ch-txt.is-display {
	@include ch-txt-variant("display");
}
.ch-txt.is-headline,
.ch-prose :is(h1, h2, h3, h4, h5, h6) {
	@include ch-txt-variant("headline");
}
.ch-txt.is-input {
	@include ch-txt-variant("input");
}
.ch-txt.is-label {
	@include ch-txt-variant("label");
}
.ch-txt.is-prose {
	@include ch-txt-variant("prose");
}

.ch-display-case-escape {
	font-family: ch-ff("primitive_montserrat_standard"), sans-serif;
	text-transform: none;
}

// Notes
// -----
// 01. By default, symbols are set inline, aligned to the baseline, and
//     inherit the font-size of their parent.
// 02. Symbols to be set as a superscript.
// 03. For *already* styled superscript symbols to be set as superscript,
//     which would include things such as the servicemark or trademark symbol.
// 04. Symbols to be set as a subscript.
// 05. Symbols to be shrunk down and aligned at the baseline.

.ch-legal-symbol {
	:where(&) {
		// 01
		display: inline !important;
		color: inherit !important;
		font-family: ch-ff("body") !important;
		font-size: 1em !important;
		font-style: normal !important;
		font-weight: inherit !important;
		letter-spacing: 0px !important;
		line-height: inherit !important;
		text-align: center !important;
		text-decoration: none !important;
		text-transform: none !important;
		vertical-align: baseline !important;
		white-space: nowrap !important;
	}

	:where(&.is-sup) {
		// 02
		font-size: clamp(10px, 1ex, 18px) !important;
		line-height: 1 !important;
		vertical-align: top !important;
	}

	:where(&.is-sup-sym) {
		// 03
		font-size: clamp(10px, 1ex, 18px) !important;
		line-height: 1 !important;
		vertical-align: text-top !important;
	}

	:where(&.is-sub) {
		// 04
		font-size: clamp(10px, 1ex, 18px) !important;
		line-height: 1 !important;
		vertical-align: bottom !important;
	}

	:where(&.is-baseline) {
		// 05
		font-size: clamp(10px, 1ex, 18px) !important;
		line-height: 1 !important;
		vertical-align: baseline !important;
	}
}

// Shared
// ------
// 01. Remove padding bottom *only* for .is-clamped text *only* in Safari, as
//     it will show the tops of the next line.

.ch-txt.is-body,
.ch-txt.is-caps,
.ch-txt.is-caption,
.ch-txt.is-display,
.ch-txt.is-headline,
.ch-txt.is-input,
.ch-txt.is-label,
.ch-txt.is-prose,
.ch-prose :is(p, ul, ol, h1, h2, h3, h4, h5, h6) {
	:where(i, em) {
		font-style: italic;
	}

	:where(&):where(.is-clamped) {
		--ch-txt-overflow: hidden;
		--ch-txt-display: -webkit-box;
		--ch-txt-text-overflow: ellipsis;

		-webkit-box-orient: vertical;
	}

	:where(&):where(.is-ellipsis) {
		--ch-txt-overflow: hidden;
		--ch-txt-text-overflow: ellipsis;
		--ch-txt-white-space: nowrap;
	}

	@media not all and (min-resolution: 0.001dpcm) {
		// 01
		@supports (-webkit-appearance: none) and (stroke-color: transparent) {
			:where(&):where(.is-clamped) {
				padding-block-end: 0;
			}
		}
	}
}

// Lists
// =============================================================================
// 01. Remove list styles by default, as there are typically more situations
//     where we need unstyled list markup. List styles can be added back
//     contextually (e.g. via a "Prose" component, et cetera).

:where(ul) {
	list-style: none;
}
:where(ol) {
	margin-left: 22px;
}

// Anchors
// =============================================================================

:where(a) {
	color: #6629b7;
	text-decoration: none;
	cursor: pointer;
}

:where(main p a) {
	font-weight: 700;
}

// Miscellaneous
// =============================================================================

:where(abbr[title]) {
	border-bottom: none;
	text-decoration: underline dotted;
}
