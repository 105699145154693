.error-page {
	background-image: url("https://assets.childrens.com/m/63fdaf0449a21d5c/webimage-CMC-20Exterior(D100326030)-11x14.jpg");
	color: #323a45;
	background-size: cover;
	height: 600px;
	padding-top: 40px;
	section {
		text-align: center;
		width: 96%;
		max-width: 470px;
		margin: 0 auto;
		border-radius: 0.4em;
		background: rgba(255, 255, 255, 0.6);
		font-size: .965em;

		.error-header {
			padding: 20px 0 40px 0;
			width: 100%;
			background: rgba(255, 255, 255, 0.7);
			border-radius: 0.4em 0.4em 0 0;
		}

		.error-body {
			padding: 0 0 30.8px 0;
			line-height: 1.1;

			.section-icon {
				background-color: #193d67;
				display: inline-flex;
				align-items: center;
				justify-content: center;
				width: 56px;
				height: 56px;
				border-radius: 50%;
				margin-top: -28px;
			}
			h1 {
				font-size: 7em;
				font-weight: 600;
				margin: 0;
				padding-top:14px;
			}
			h4 {
				font-size: 1.25em;
				text-transform: uppercase;
				padding-bottom: 15.4px;
				font-weight: inherit;
			}
		}
	}
}
