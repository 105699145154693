// =============================================================================
// _BASE-REBOOT.SCSS
// -----------------------------------------------------------------------------
// Site styles.
// =============================================================================

// =============================================================================
// TABLE OF CONTENTS
// -----------------------------------------------------------------------------
//   01. *
//   02. <html>
//   03. Outlines
//   05. Add `max-width: 100%;` for Responsiveness
//   06. Images and Vectors
//   07. Audio and Video
//   08. Button
// =============================================================================

// *
// =============================================================================
// 01. Avoid 300ms click delay on touch devices that support the `touch-action`
//     CSS property. In particular, unlike most other browsers, IE11+Edge on
//     Windows 10 on touch devices and IE Mobile 10-11 DON'T remove the click
//     delay when `<meta name="viewport" content="width=device-width">` is
//     present. However, they DO support removing the click delay via
//     `touch-action: manipulation`.

*,
*::before,
*::after {
	margin: 0;
	border: 0;
	padding: 0;
	box-sizing: inherit;
	font-family: inherit;
	line-height: inherit;
	color: currentColor;
	touch-action: manipulation; // 01
}

#gatsby-announcer {
	bottom: 0;
}

// <html>
// =============================================================================
// 01. Prevent adjustments of font size after orientation changes in IE on
//     Windows Phone and in iOS.
// 02. Setting @viewport causes scrollbars to overlap content in IE11 and Edge,
//     so we force a non-overlapping, non-auto-hiding scrollbar to counteract.
// 03. Change the default tap highlight to be completely transparent in iOS.
// 04. Disables callouts on mobile when pressing and holding certain elements
// 05. Experimental "dark mode" for just a few lines of CSS.

html {
	@each $key, $value in $ch_font_size_map {
		#{ch-format-custom-property("ch-fs",          $key)}: #{$value};
	}
	@each $key, $value in $ch_font_size_display_map {
		#{ch-format-custom-property("ch-fs-display",  $key)}: #{$value};
	}
	@each $key, $value in $ch_font_size_headline_map {
		#{ch-format-custom-property("ch-fs-headline", $key)}: #{$value};
	}
	@each $key, $value in $ch_font_size_caps_map {
		#{ch-format-custom-property("ch-fs-caps",     $key)}: #{$value};
	}
	@each $key, $value in $ch_font_size_body_map {
		#{ch-format-custom-property("ch-fs-body",     $key)}: #{$value};
	}
	@each $key, $value in $ch_font_size_prose_map {
		#{ch-format-custom-property("ch-fs-prose",    $key)}: #{$value};
	}
	@each $key, $value in $ch_font_size_caption_map {
		#{ch-format-custom-property("ch-fs-caption",  $key)}: #{$value};
	}
	@each $key, $value in $ch_font_size_label_map {
		#{ch-format-custom-property("ch-fs-label",    $key)}: #{$value};
	}
	@each $key, $value in $ch_font_size_input_map {
		#{ch-format-custom-property("ch-fs-input",    $key)}: #{$value};
	}
	@each $key, $value in $ch_font_family_map {
		#{ch-format-custom-property("ch-ff",          $key)}: #{$value};
	}
	@each $key, $value in $ch_font_weight_map {
		#{ch-format-custom-property("ch-fw",          $key)}: #{$value};
	}
	@each $key, $value in $ch_line_height_map {
		#{ch-format-custom-property("ch-lh",          $key)}: #{$value};
	}
	@each $key, $value in $ch_letter_spacing_map {
		#{ch-format-custom-property("ch-ls",          $key)}: #{$value};
	}
	@each $key, $value in $ch_text_transform_map {
		#{ch-format-custom-property("ch-tt",          $key)}: #{$value};
	}
}

html {
	@each $key, $value in $ch_easing_map {
		#{ch-format-custom-property("ch-ease", $key)}: #{$value};
	}
	@each $key, $value in $ch_duration_map {
		#{ch-format-custom-property("ch-dur", $key)}: #{$value};
	}
	@each $key, $value in $ch_z_index_map {
		#{ch-format-custom-property("ch-z", $key)}: #{$value};
	}
	@each $key, $value in $ch_shadow_map {
		#{ch-format-custom-property("ch-s", $key)}: #{$value};
	}
	@each $key, $value in $ch_opacity_map {
		#{ch-format-custom-property("ch-o", $key)}: #{$value};
	}
}

html {
	@each $key, $value in $ch_dimension_map {
		#{ch-format-custom-property("ch-d", $key)}: #{$value};
	}
}

html {
	@each $key, $value in $ch_hsl_map {
		#{ch-format-custom-property("ch-hsl", $key)}: #{$value};
	}
	@each $key, $value in $ch_color_map {
		#{ch-format-custom-property("ch-c", $key)}: #{$value};
	}
}

html {
	box-sizing: border-box;
	text-rendering: optimizeLegibility;
	-webkit-text-size-adjust: 100%; // 01
	-ms-text-size-adjust: 100%; // 01
	-ms-overflow-style: scrollbar; // 02
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0); // 03
	-webkit-touch-callout: none; // 04
	-o-tab-size: 4;
	tab-size: 4;
}

html {
	font-family: ch-ff("body");
	font-size: $ch_root_font_size;
	font-style: normal;
	font-weight: ch-fw("body_normal");
	line-height: 1;
	color: ch-c("root");
	background-color: unset;
	overscroll-behavior-x: none;
	overscroll-behavior-y: none;

	:where(&.is-inverted) {
		// 05
		filter: invert(100%);

		* {
			background-color: inherit;
		}

		video,
    // img:not([src*=".png"]) {
    img {
			filter: invert(100%) !important;
		}
	}
}

body {
	overscroll-behavior-x: none;
	overscroll-behavior-y: none;
}

// Outlines
// =============================================================================
// 01. Suppress the focus outline on elements that cannot be accessed via
//     keyboard. This prevents an unwanted focus outline from appearing around
//     elements that might still respond to pointer events.
// 02. Moved into its own selector to avoid issues in browsers that do not
//     support :has() yet. This is a helper class to place outline on
//     non-focusable parent when only its immediate, focusable child has focus
//     (typically needed because the child has a hidden overflow that would
//     obscure the outline).
// 03. Only show outlines when focus should be visible, not on "faux focus."
// 04. Helper class to show style programmatially when needed.
// 05. Helper class to place outline on non-focusable parent when any element
//     within its tree has focus.

[tabindex="-1"]:focus {
	outline: none !important; // 01
}

body button:focus {
	outline-color: transparent;
}

.ch-focus-for-child:has(> :not(.ch-ignore-focus-for-child):focus-visible) {
	// 02
	outline-width: var(--ch-outline-width, #{ch-d("outline_width")});
	outline-style: var(--ch-outline-style, solid);
	outline-color: var(--ch-outline-color, #{ch-c("outline")});
	outline-offset: var(--ch-outline-offset, #{ch-d("outline_offset")});
}

:focus-visible, // 03
.ch-focus-visible, // 04
.ch-focus-for-ancestors:focus-within, // 05
body button:focus-visible {
	outline-width: var(--ch-outline-width, #{ch-d("outline_width")});
	outline-style: var(--ch-outline-style, solid);
	outline-color: var(--ch-outline-color, #{ch-c("outline")});
	outline-offset: var(--ch-outline-offset, #{ch-d("outline_offset")});
}

// Add `max-width: 100%;` for Responsiveness
// =============================================================================

:where(img, svg, video, canvas, iframe) {
	max-width: 100%;
	height: auto;
}

// Images and Vectors
// =============================================================================

:where(img, svg) {
	vertical-align: middle;
}

// Audio and Video
// =============================================================================

:where(audio) {
	display: block;
}

:where(audio:not([controls])) {
	display: none;
	height: 0;
}

:where(video) {
	display: block;
	height: auto;
}

// Button
// =============================================================================
// 01. Avoid issues when setting button as a tag on higher level layouts.

:where(button) {
	text-align: inherit; // 01
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
}
