// =============================================================================
// _BASE-HELPERS.SCSS
// -----------------------------------------------------------------------------
// Site styles.
// =============================================================================

// =============================================================================
// TABLE OF CONTENTS
// -----------------------------------------------------------------------------
//   01. Responsive Hide
//   02. Typography
//   03. Bleed
//   04. Fullwidth
//   05. Centering
//   06. Visibility
//   07. Kill Scroll
//   08. Overflow Hidden
//   09. Expand Hitbox
//   10. Outlines
//   11. Text Overflow Ellipsis
//   12. Flexbox
//   13. Inline Containment Context
//   14. Isolation
//   15. Position
//   16. No Height Sticky
// =============================================================================

// Responsive Hide
// =============================================================================

@each $bp_slug, $bp_value in $ch_breakpoints_map {
	@include ch-break-only($bp_slug) {
		.ch-hide-#{$bp_slug}-only {
			display: none !important;
		}
	}
	@include ch-break-up($bp_slug) {
		.ch-hide-#{$bp_slug}-up {
			display: none !important;
		}
	}
	@include ch-break-down($bp_slug) {
		.ch-hide-#{$bp_slug}-down {
			display: none !important;
		}
	}
}
.ch-hide {
	display: none !important;
}

// Typography
// =============================================================================

// Responsive Type Scale
// ---------------------

@each $ts_variant in $ch_type_sets_list {
	@each $fs_step, $fs_value in $ch_font_size_map {
		.ch-txt.is-#{$ts_variant}.ch-ts-#{$fs_step} {
			--ch-txt-font-size: #{ch-fs(#{$ts_variant}-#{$fs_step})};
		}
	}

	@each $fs_step, $fs_value in $ch_font_size_map {
		@each $bp_slug, $bp_value in $ch_breakpoints_map {
			@include ch-break-only($bp_slug) {
				.ch-txt.is-#{$ts_variant}.ch-ts-#{$fs_step}-#{$bp_slug}-only {
					--ch-txt-font-size: #{ch-fs(#{$ts_variant}-#{$fs_step})};
				}
			}
		}
	}
}

// Inline Text Links
// -----------------

.ch-inline-link {
	@include ch-inline-link();
}

// Bleed
// =============================================================================
// 01. Forces full page bleed from <Container/> at a breakpoint to allow
//     fullwidth content on mobile devices without needing to alter markup.

$bleed_size: var(--ch-bleed-size, #{ch-d("container_gutter")});

@each $bp_slug, $bp_value in $ch_breakpoints_map {
	@include ch-break-down($bp_slug) {
		.ch-bleed-#{$bp_slug}-down {
			width: calc(100% + (#{$bleed_size} * 2)) !important;
			max-width: none !important;
			margin-inline-start: calc(#{$bleed_size} * -1) !important;
			margin-inline-end: calc(#{$bleed_size} * -1) !important;
		}

		.ch-bleed-zero-border-#{$bp_slug}-down {
			border-inline-start-width: 0 !important;
			border-inline-end-width: 0 !important;
			border-radius: 0 !important;
		}

		.ch-bleed-zero-padding-#{$bp_slug}-down {
			padding-inline-start: $bleed_size !important;
			padding-inline-end: $bleed_size !important;
		}
	}
}

// Fullwidth
// =============================================================================

@each $bp_slug, $bp_value in $ch_breakpoints_map {
	@include ch-break-down($bp_slug) {
		.ch-fullwidth-#{$bp_slug}-down {
			width: 100%;
		}
	}
	@include ch-break-up($bp_slug) {
		.ch-fullwidth-#{$bp_slug}-up {
			width: 100%;
		}
	}
}

// Visibility
// =============================================================================

.ch-visually-hidden,
.ch-visually-hidden-focusable:not(:focus):not(:focus-within) {
	overflow: hidden !important;
	position: absolute !important;
	width: 1px !important;
	height: 1px !important;
	min-width: 1px !important;
	max-width: 1px !important;
	min-height: 1px !important;
	max-height: 1px !important;
	margin: -1px !important;
	border: 0 !important;
	padding: 0 !important;
	clip: rect(0 0 0 0) !important;
}

// Kill Scroll
// =============================================================================
// Selectors are broken out until :has() support is better, at which point they
// can be combined.
//
// 01. Use dvh to set 100% *dynamic* viewport height on mobile.
// 02. Sets how an element's region can be manipulated by a touchscreen user.

html.ch-kill-scroll {
	&,
	body {
		height: ch-d("height_fullscreen_dynamic") !important; // 01
	}

	body {
		overflow: hidden !important;
		touch-action: none !important; // 02
		max-height: ch-d("height_fullscreen_dynamic") !important; // 01
		padding-right: ch-d("os_scrollbar") !important;
	}
}

html:has(dialog.ch-overlay[open]) {
	&,
	body {
		height: ch-d("height_fullscreen_dynamic") !important; // 01
	}

	body {
		overflow: hidden !important;
		touch-action: none !important; // 02
		max-height: ch-d("height_fullscreen_dynamic") !important; // 01
		padding-right: ch-d("os_scrollbar") !important;
	}
}

// Overflow Hidden
// =============================================================================

.ch-clip {
	overflow: hidden !important;
}

// Expand Hitbox
// =============================================================================

$expand_hitbox_x_start: var(
	--ch-expand-hitbox-x-start,
	var(--ch-expand-hitbox-x, var(--ch-expand-hitbox, #{ch-d("space_4x")}))
);
$expand_hitbox_x_end: var(
	--ch-expand-hitbox-x-end,
	var(--ch-expand-hitbox-x, var(--ch-expand-hitbox, #{ch-d("space_4x")}))
);
$expand_hitbox_y_start: var(
	--ch-expand-hitbox-y-start,
	var(--ch-expand-hitbox-y, var(--ch-expand-hitbox, #{ch-d("space_4x")}))
);
$expand_hitbox_y_end: var(
	--ch-expand-hitbox-y-end,
	var(--ch-expand-hitbox-y, var(--ch-expand-hitbox, #{ch-d("space_4x")}))
);

.ch-expand-hitbox-before::before,
.ch-expand-hitbox-after::after {
	content: "";
	position: absolute;
	inset-inline-start: calc(#{$expand_hitbox_x_start} * -1);
	inset-inline-end: calc(#{$expand_hitbox_x_end} * -1);
	inset-block-start: calc(#{$expand_hitbox_y_start} * -1);
	inset-block-end: calc(#{$expand_hitbox_y_end} * -1);
}

a.ch-expand-anchor-hitbox-before::before,
.ch-expand-anchor-hitbox-before a::before,
a.ch-expand-anchor-hitbox-after::after,
.ch-expand-anchor-hitbox-after a::after {
	content: "";
	position: absolute;
	inset: 0;
}

.ch-expand-full-hitbox-before::before,
.ch-expand-full-hitbox-before ::before,
.ch-expand-full-hitbox-after::after,
.ch-expand-full-hitbox-after ::after {
	content: "";
	position: absolute;
	inset: 0;
}

// Outlines
// =============================================================================

.ch-inset-outline {
	outline-offset: calc(#{ch-d("outline_width")} * -1) !important;
}

.ch-really-inset-outline {
	outline-offset: calc(
		(#{ch-d("outline_width")} + #{ch-d("outline_offset")}) * -1
	) !important;
}

.ch-has-inverted-outline {
	--ch-outline-color: #{ch-c("always_light_on_surface_strong")};
}

// Text Overflow Ellipsis
// =============================================================================

.ch-text-truncate {
	overflow: hidden !important;
	text-overflow: ellipsis !important;
	white-space: nowrap !important;
}

// Flexbox
// =============================================================================

.ch-no-shrink {
	flex-shrink: 0 !important;
}
.ch-grow {
	flex-grow: 1 !important;
}

// Inline Containment Context
// =============================================================================
// 01. Specifies a containment context to be used in conjunction with container
//     queries on child elements.

.ch-inline-containment-context {
	container-type: inline-size;
}

// Isolation
// =============================================================================

.ch-isolate,
.has-bg {
	isolation: isolate;
}

// Position
// =============================================================================

.ch-position-relative {
	position: relative;
}

// No Height Sticky
// =============================================================================

.ch-no-height-sticky {
	width: 100%;
	min-width: 1px;
	max-width: 100%;
	height: 0;
	min-height: 0;
	max-height: 0;
	position: sticky;
	z-index: 9999;
	top: var(--ch-no-height-sticky-top, 0px);
	bottom: var(--ch-no-height-sticky-bottom, 0px);
}
