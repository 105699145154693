@import "/home/ghrunner/actions-runner/_work/childrens-app/childrens-app/apps/childrens-app/src/styles/shared/shared.scss";
@layer base;

@layer base {
	@import "_base-reboot";
	@import "_base-typography";
	@import "_base-helpers";
	@import "_error";
}

.legacy-component-builder-wrapper {
	@import "_base-typography";
	@import "_base-helpers";
	@import "_error";
}

@media print {
	/* General Element Styling */
	* {
		color: black !important;

		&::before,
		&::after {
			color: black !important;
		}
	}

	/* Typography */
	p,
	span:not([itemprop="name"]) {
		font-size: 14px !important;
		line-height: 1.4 !important;
	}

	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		font-size: 1.2rem !important;
	}

	ul li {
		margin-top: 0;
	}

	/* Images */
	img {
		display: block !important;
		visibility: visible !important;
	}

	address img {
		display: none !important;
		padding-bottom: 0 !important;
		border: none !important;
	}

	.header-body a img {
		width: 107.25px !important;
		height: 27px !important;
	}

	/* Elements to Hide */
	.header-alert-banner,
	.embeddedServiceHelpButton,
	.page-subnav,
	.read-more-toggle {
		display: none !important;
		padding-bottom: 0 !important;
		border: none !important;
	}

	/* Read-More Adjustments */
	.read-more {
		max-height: none !important;
		overflow: visible !important;

		&.collapsed::after {
			content: none !important;
		}
	}

	/* Related Topics */
	.related-topics-container {
		border-top-color: black !important;
	}

	/* Container Adjustments */
	.ch-container {
		display: flex !important;
		flex-direction: column !important;
	}

	/* Provider Card */
	.provider-card {
		margin-bottom: 10px !important;

		.provider-thumbnail {
			margin-right: 0 !important;

			img {
				border-radius: 8px !important;
				width: 75px !important;
				height: 75px !important;
			}
		}
	}

	/* Footer Adjustments */
	.ch-footer-section.is-main {
		display: flex !important;
		flex-direction: column !important;
		margin: 0 auto !important;
		gap: 0 !important;
		padding-top: 0 !important;
	}

	.ch-footer-container {
		text-align: left !important;
		margin: 0 auto !important;

		h3,
		h4 {
			font-size: 90% !important;
		}

		a {
			font-size: 14px !important;

			&::after:not([href*="tel"]) {
				content: " (" attr(href) ")" !important;
			}
		}
	}

	nav.ch-footer-grid ul {
		line-height: 1.2 !important;
		gap: 0 !important;
		padding-bottom: 10px !important;
	}

	.ch-footer-section.is-banner {
		.ch-footer-social-links {
			flex-direction: column !important;
			align-items: start !important;
		}

		a {
			height: auto !important;
			display: flex !important;
			width: 100% !important;
			font-size: 14px !important;

			&::after {
				content: " (" attr(href) ")" !important;
			}
		}
	}
	.slick-slider {
		button {
			display: none !important;
		}
	}
}
